<template>
  <div class="text-left">
    <BaseHeader :title="'Connections'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>

      <!-- {{this.$store.state.site_id }} -->
      <div class="card mt-2 border-0 bg-white shadow">
        <div class="card-body text-right d-flex" style>
          <!-- <base-select-site @siteSelected="getConnections"></base-select-site> -->
          <v-spacer></v-spacer>
          <button
            color="primary"
            class="my-auto btn btn-add ml-2"
            size="small"
            @click.stop="crudAction"
            >Update</button
          >
        </div>
        <!-- datatable -->
        <div
          class="table-responsive"
          v-if="connections && connections.length > 0"
        >
          <table class="table">
            <tr v-for="(conn, index) in connections" :key="index + 'conn'">
              <td>{{ conn.subject || "" }}</td>
              <td :class="conn.value ? 'success--text' : 'error--text'">
                {{ conn.value || "Not Set" }}
              </td>
            </tr>
          </table>
        </div>
        <!-- /datatable -->
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="connectionsForm.busy">
          <v-card-title class="text-h5"> Update connection </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="connectionsForm"
              @submit.prevent="createConnection"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted">Mail Server Configs</span>
                </div>

                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                      dense
                      outlined
                      label="SMTP Server"
                      name="smtp_server"
                      type="smtp_server"
                      v-model="connectionsForm.smtp_server"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="smtp_server"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      dense
                      outlined
                      label="SMTP port"
                      name="smtp_port"
                      v-model="connectionsForm.smtp_port"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="smtp_port"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      label="SMTP user"
                      name="smtp_user"
                      type="text"
                      min="0"
                      v-model="connectionsForm.smtp_user"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="smtp_user"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6" class="pt-0">
                    <v-text-field
                      dense
                      outlined
                      label="SMTP password"
                      name="smtp_password"
                      type="password"
                      min="0"
                      v-model="connectionsForm.smtp_password"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="connectionsForm"
                            class="v-messages theme--light error--text"
                            field="smtp_password"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                

                <v-btn-toggle>
                  <v-btn
                    :disabled="connectionsForm.busy"
                    type="submit"
                    color="teal"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Connections",
  computed: {
    ...mapState("connections", [
      "connectionsForm",
      "loading",
      "connections",
      "headers",
    ]),
    // ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
    };
  },

  methods: {
    ...mapActions("connections", ["getConnections"]),
    // ...mapActions("clients", ["getClients"]),
    createConnection() {
      this.edit
        ? this.connectionsForm
            .put(
              `${this.$baseUrl}/Setups/Connections/${this.$store.state.site_id}`
            )
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Connection updated successifully.`,
                style: "success",
              });
              this.getConnections();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while updating connection`,
                style: "danger",
              });
            })
        : this.connectionsForm
            .post(
              `${this.$baseUrl}/Setups/Connections/${this.$store.state.site_id}`
            )
            .then((res) => {
              this.$store.state.dialog = false;
              this.$notify({
                title: "Success",
                text: `Connection created successifully.`,
                style: "success",
              });
              this.getConnections();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              this.$notify({
                title: "Error",
                text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating connection`,
                style: "danger",
              });
            });
    },

    editMode(item) {
      Object.assign(this.connectionsForm, { ...item });
    },

    crudAction() {
      if (this.$store.state.site_id) {
        this.edit = false;
        this.connections.map((item) => {
          this.connectionsForm[item.subject] = item.value;
        });
        this.$store.state.dialog = true;
      } else {
        this.$notify({
          title: "Error",
          text: "Please select a site to proceed",
          style: "danger",
        });
      }
    },
  },
  async mounted() {
    this.$store.state.site_id = this.$route.params.siteid
    if (this.$store.state.site_id) {
      await this.getConnections();
    }
  },
};
</script>